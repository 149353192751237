// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--u8+R7";
export var breakme = "styles-module--breakme--0vYG+";
export var content = "styles-module--content--vgZfM";
export var header = "styles-module--header--+T-DD";
export var holder = "styles-module--holder--kgMed";
export var howHolder = "styles-module--howHolder--AteY0";
export var image = "styles-module--image--evS3q";
export var kaynakca = "styles-module--kaynakca--1KOVl";
export var kidsHolder = "styles-module--kidsHolder--3NF83";
export var listHolder = "styles-module--listHolder--4SMYU";
export var productHolder = "styles-module--productHolder--BpijA";
export var rightCoughHolder = "styles-module--rightCoughHolder--JMY-G";
export var typesHolder = "styles-module--typesHolder--wOuH2";
export var whatHolder = "styles-module--whatHolder--t9ITb";
export var whyHolder = "styles-module--whyHolder--T17Vt";